$(document).ready(function () {
    Fancybox.bind('[data-fancybox]', {
        // Your custom options
    });

    // Menu
    $('.header-menu__open').click(function () {
        $this = $(this);

        if ($this.hasClass('header-menu__open_opened')) {
            $('.header-menu').hide();
            $this.removeClass('header-menu__open_opened');
            $('.header').removeClass('header_menu_opened');
        } else {
            $('.header-menu').fadeIn();
            $('.header').addClass('header_menu_opened');
            $this.addClass('header-menu__open_opened');
        }
    });

    // Popup
    $('body').on('click', '.open-popup', function (e) {
        e.preventDefault();

        var el = $(this).data('popup');
        var $this = $(this);

        $('.popup-' + el)
            .find('[name="formDesc"]')
            .val($(this).data('form-desc'));

        if ($('.header-menu__open').hasClass('header-menu__open_opened')) {
            if ($('body').innerWidth() <= 1024) {
                // enableScrolling();
                $('.header-menu').hide();
                $('.header-menu__open').removeClass('header-menu__open_opened');
                $('.header').removeClass('header_menu_opened');
            }
        }

        Fancybox.show(
            [
                {
                    src: '#popup-' + el,
                    type: 'inline',
                },
            ],
            {
                padding: 0,
                compact: false,
                centred: true,
                hideScrollbar: true,
                dragToClose: false,
            }
        );
    });

    // Services
    if ($('body').innerWidth() <= 580) {
        $('.services-items').slick({
            arrows: false,
            centerMode: true,
            infinite: true,
            variableWidth: true,
        });
    }

    $(window).on('resize', function () {
        if ($('body').innerWidth() <= 580) {
            try {
                $('.services-items').slick({
                    arrows: false,
                    centerMode: true,
                    infinite: true,
                    variableWidth: true,
                });
            } catch (e) {}
        } else {
            try {
                $('.services-items').slick('unslick');
            } catch (e) {}
        }
    });

    $('.services__load').click(function () {
        $('.services-table').find('tr.hidden').fadeIn().removeClass('hidden');
        $('.services-t-item.hidden').fadeIn().removeClass('hidden');
    });

    $('.strategies-item__arrow').click(function () {
        var $item = $(this).closest('.strategies-item');

        if (!$item.hasClass('strategies-item_active')) {
            $item.addClass('strategies-item_active');
            $item.find('.strategies-item__description').slideDown();
        } else {
            $item.removeClass('strategies-item_active');
            $item.find('.strategies-item__description').slideUp();
        }
    });

    // Team
    $('.team-slider').slick({
        slidesToShow: 4,
        arrows: true,
        dots: false,
        prevArrow: '<div class="slider__arrow slider__arrow-prev"></div>',
        nextArrow: '<div class="slider__arrow slider__arrow-next"></div>',
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 580,
                settings: {
                    arrows: false,
                    centerMode: true,
                    slidesToShow: 2,
                    infinite: true,
                    variableWidth: true,
                },
            },
        ],
    });

    // Work
    if ($('body').innerWidth() <= 1024) {
        $('.work-slider').slick({
            adaptiveHeight: true,
            dots: false,
            prevArrow: '<div class="slider__arrow slider__arrow_blue slider__arrow-prev"></div>',
            nextArrow: '<div class="slider__arrow slider__arrow_blue slider__arrow-next"></div>',
        });
    }

    $(window).on('resize', function () {
        if ($('body').innerWidth() <= 1024) {
            try {
                $('.work-slider').slick({
                    adaptiveHeight: true,
                    dots: false,
                    prevArrow: '<div class="slider__arrow slider__arrow_blue slider__arrow-prev"></div>',
                    nextArrow: '<div class="slider__arrow slider__arrow_blue slider__arrow-next"></div>',
                });
            } catch (e) {}
        } else {
            try {
                $('.work-slider').slick('unslick');
            } catch (e) {}
        }
    });

    $('.question-item').click(function () {
        var $this = $(this);

        if ($this.hasClass('question-item_active')) {
            $this.removeClass('question-item_active');
            $this.find('.question-item__body').slideUp();
        } else {
            $this.addClass('question-item_active');
            $this.find('.question-item__body').slideDown();
        }
    });

    $('form').submit(function () {
        return false;
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function (i, pair) {
            var cObj = obj,
                pObj,
                cpName;
            $.each(pair.name.split('.'), function (i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    function submitForm(form) {
        if ($(form).hasClass('no-valid')) {
            return false;
        }

        var $button = $(form).find('.form__submit');

        if ($button.attr('disabled')) return false;

        $button.attr('disabled', true);

        var formData = getFormObject($(form).serializeArray());

        axios({
            method: 'post',
            url: '/sender.php',
            data: formData,
            config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
            withCredentials: true,
        })
            .then(function (response) {
                $(form).find('input[type=text], textarea').val('');

                Fancybox.close();
                Fancybox.show([{ src: '#popup-thanks', type: 'inline' }]);

                $button.attr('disabled', false);
            })
            .catch(function (response) {
                $button.attr('disabled', false);
            });
    }

    // $('[name="phone"]').inputmask({
    //     mask: '+35\\9 (###) ###-###',
    // });

    $('form').each(function () {
        $(this).validate({
            rules: {
                name: {
                    required: true,
                },
                surname: {
                    required: true,
                },
                phone: {
                    required: true,
                },
            },
            messages: {
                name: 'Обязательное поле',
                phone: 'Введите корректный номер телефона',
            },
            submitHandler: function (form) {
                submitForm(form);
            },
        });
    });

    var latitude = $('#google-map').data('latitude');
    var longitude = $('#google-map').data('longitude');
    function initialize_map() {
        var myLatlng = new google.maps.LatLng(latitude, longitude);
        var mapOptions = {
            zoom: 14,
            scrollwheel: false,
            center: myLatlng,
        };
        var map = new google.maps.Map(document.getElementById('google-map'), mapOptions);
        var contentString = '';
        var infowindow = new google.maps.InfoWindow({
            content: '<div class="map-content"><ul class="address">' + $('.address').html() + '</ul></div>',
        });
        var marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
        });
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize_map);
});